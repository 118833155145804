<template>
  <template v-if="!displayForm(`showAddSingleStockWriteOffItemForm_${item.id}`)">
    <div class="flex w-full py-2">
      <div class="grid grid-cols-12 w-full">

          <div class="col-span-12 md:col-span-6 lg:col-span-1">
            <item-text title="ID" :text="item.id" />
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <item-text title="Nosaukums" :text="item.item.name" />
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-2">
            <item-text title="Noliktava" :text="warehouse.name" />
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-2">
            <item-text title="Mainītais daudzums" :text="item.quantity" />
          </div>

          <template v-if="item.SingleStockWriteOffItemDetails === 'App\\CatalogItem'">
            <div class="col-span-12 md:col-span-6 lg:col-span-3">
              <item-text title="Preces kods" :text="item.item.sku" />
            </div>
          </template>

          <template v-if="item.SingleStockWriteOffItemDetails === 'App\\InventoryItem'">
            <div class="col-span-12 md:col-span-6 lg:col-span-3">
              <item-text title="Inventāra nr." :text="item.item.uuid" />
            </div>
          </template>

          <template v-if="item.notes">
            <div class="col-span-12 md:col-span-6 lg:col-span-3">
              <item-text title="Piezīmes" :text="item.notes" />
            </div>
          </template>

      </div>

      <Button icon="pencil" @click="showForm(`showAddSingleStockWriteOffItemForm_${item.id}`)" />
      <Button icon="delete" @click="deleteItem" />
    </div>
  </template>

  <template v-if="displayForm(`showAddSingleStockWriteOffItemForm_${item.id}`)">
    <EditStockWriteOffItemDetails :item="item" :technicalDocument="technicalDocument" />
  </template>

</template>

<script>
import {mapGetters} from "vuex"
import ItemText from "@/components/Components/ItemText"
import EditStockWriteOffItemDetails from "@/components/Stock/TehnicalDocuments/WriteOff/EditStockWriteOffItemDetails"

export default {
  name: "SingleStockCorrectionItemDetails",
  components: {
    ItemText,
    EditStockWriteOffItemDetails,
  },
  props: ["item", "warehouse", "technicalDocument"],
  computed:{
      ...mapGetters({
          formsForDisplay: 'formsForDisplay',
      })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddForm(){
        this.$store.dispatch('removeAllFormsForDisplay')
    },
    deleteItem() {
      if(confirm('Vai esi drošs?')) {
        this.$Progress.start()
          this.$store.dispatch('deleteWriteOffItem', {
            item_id: this.item.id,
            document_id: this.technicalDocument.id,
          })
      }
    }
  }
}
</script>

<style>

</style>